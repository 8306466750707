import React from 'react'
import {Slide} from "react-reveal";
import Title from "../../../Components/Title/Title";
import o from './OpenBank.module.scss'
import Footer from "../../../Components/Footer/Footer";
import {Helmet} from "react-helmet";

export const OpenBank = () => {

    const color = {
        color: 'white'
    }
    const Figma = {
        borderRadius: '18px',
        border: 'none',
        width: '100%',
        height: '100%'
    }

    return (
        <div className={o.MainWrapper}>

            <Helmet>
                <meta charSet="utf-8" />
                <title>Банк Открытие</title>
            </Helmet>

            <div id='start' className={o.FirstBlock}>
                <Slide duration={1500} right>
                    <div className={o.BlockForTitle}>
                        {/*<h1 className={k.NameTitle}>Проектировка интерфейса "живой очереди" для сотрудников банка</h1>*/}
                        {/*<h2 className={k.NameTitle}>*Задание выполнено в качестве стажировки*</h2>*/}
                        <Title
                            titleStyle={color}
                            TitleBig='Проектировка интерфейса для отслеживания эффективности сотрудников'
                        />
                    </div>
                </Slide>
            </div>

            <div className={o.SecondBlockWrapper}>
                <div className={o.SecondBlock}>
                    <div className={o.ScreensWrapper}>
                        <img className={o.ImgStyle} src="/OpenBank/1.png" alt=""/>
                        <img className={o.ImgStyle} src="/OpenBank/2.png" alt=""/>
                        <img className={o.ImgStyle} src="/OpenBank/3.png" alt=""/>
                    </div>
                </div>
            </div>

            <div className={o.ThirdBlockWrapper}>
                <div className={o.ThirdBlock}>
                    <div className={o.ScreensWrapper}>
                        <img className={o.ImgStyle} src="/OpenBank/prem.png" alt=""/>
                        <img className={o.ImgStyle} src="/OpenBank/effect.png" alt=""/>
                        <img className={o.ImgStyle} src="/OpenBank/profile.png" alt=""/>
                    </div>
                </div>
            </div>

            {/*Figma*/}
            <section className={o.WrapperForFigma}>
                <div className={o.FigmaExport}>

                    <div className={o.Title}>
                        <Title
                            TitleBig='Макет из Figma, welcome'
                        />
                    </div>

                    <iframe style={Figma}
                            title={'OpenBank'}
                            src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2Fgsv3nrM0rFsqxKM3OI0UbD%2F%25D0%25A1%25D0%25BE%25D0%25BB%25D0%25BE%25D0%25B2%25D1%258C%25D0%25B5%25D0%25B2-%25D0%2590%25D0%25BB%25D0%25B5%25D0%25BA%25D1%2581%25D0%25B0%25D0%25BD%25D0%25B4%25D1%2580----UX-%25D0%2594%25D0%25B8%25D0%25B7%25D0%25B0%25D0%25B9%25D0%25BD%25D0%25B5%25D1%2580---%25D0%25A2%25D0%25B5%25D1%2581%25D1%2582%25D0%25BE%25D0%25B2%25D0%25BE%25D0%25B5-%25D0%25B7%25D0%25B0%25D0%25B4%25D0%25B0%25D0%25BD%25D0%25B8%25D0%25B5---25.05.2022---%25D0%2591%25D0%25B0%25D0%25BD%25D0%25BA-%25D0%259E%25D1%2582%25D0%25BA%25D1%2580%25D1%258B%25D1%2582%25D0%25B8%25D0%25B5%3Fnode-id%3D0%253A1%26t%3Dw6If7KwQdtS7tny1-1"
                            allowFullScreen>
                    </iframe>
                </div>
            </section>
            <footer>
                <Footer/>
            </footer>
        </div>
    )
}

export default OpenBank;
