import React from 'react';
import 'react-dom'
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Circles} from 'react-preloaders2'
import {hydrate, render} from "react-dom";

const rootElement = document.getElementById("root");{
    render(<App/>, rootElement
    );
}

reportWebVitals();


