import React, {Component} from 'react';
import d from './DownLoadPdf.module.scss'

class DownLoadPdf extends Component {
    render() {
        return (
            <div className={`${'cursorhover'} ${d.WrapperButton}`} >
                <a href={'/resume.pdf'} download className={d.Button}>
                    <p>Скачать резюме в pdf.</p>
                </a>

                <a target='_blank' href="https://calendly.com/alexandrsolovev/" className={d.Button}>
                    <p>Забронировать встречу Calendly</p>
                </a>

                <a target='_blank' href="https://t.me/innv1" className={d.Button}>
                    <p>Написать в telegram</p>
                </a>
            </div>
        );
    }
}

export default DownLoadPdf;