import React from 'react'
import a from './Title.module.scss'

export const Title = ({title, subtitle, description, titleStyle, TitleBig}) => {
    return (
        <div className={a.WrapperTitle}>
            <h1 style={titleStyle} className={a.TitleBig}>{TitleBig}</h1>
            <h2  style={titleStyle} className={a.Text}>{title}</h2>
            <h3 style={titleStyle} className={a.Subtitle}>{subtitle}</h3>
            <p style={titleStyle}  className={a.description}>{description}</p>
        </div>
    )
}

export default Title;
