import React, {Component} from 'react';
import a from './ArtStudio.module.scss'
import Title from "../../Components/Title/Title";
import {Slide, Fade} from "react-reveal";
import Footer from '../../Components/Footer/Footer'
import Burger from "../../Components/Buttons/Burger/Burger";
import LinkButton from "../../Components/Buttons/LinkButton/LinkButton";

class ArtStudio extends Component {
    render() {
        const color = {
            color: '#fff'
        }
        return (
            <div>
                <Burger/>
                <div className={a.Wrapper}>
                    {/*Первый блок*/}
                    <Fade>
                        <div id='start' className={a.FirstBlock}>
                            <div>
                                <video controls={true} className={a.Video} src="/twinpiks.webm"/>
                                <div className={a.WrapperName}>
                                    <p className={a.Name}>„Твин Пикс”</p>
                                </div>
                            </div>
                            <div className={a.Action}>
                                <Title
                                    titleStyle={color}
                                    title={'Art Studio by'}
                                    subtitle={'ALEXANDR SOLOVEV'}
                                    // description={'“  soon „ '}
                                />
                                <LinkButton url={'https://t.me/innv1'} text={'Заказать картину'}/>
                            </div>
                        </div>
                    </Fade>
                    {/*Второй блок*/}
                    <Slide bottom>
                        <section className={a.SecondBlock}>
                            <div className={a.Gallery}>
                                <div className={a.BlockArt}>
                                    <Title titleStyle={color} TitleBig='Галерея'/>
                                    <img className={a.Art} src="/ArtStudio/hands.jpeg" alt="123"/>
                                    <div className={a.WrapperName}>
                                        <p className={a.Name}>Отрывок „Сотворение адама”</p>
                                    </div>
                                </div>
                                {/*<div className={a.BlockArt}>*/}
                                {/*    <img className={a.Art} src="/ArtStudio/anime.jpeg" alt="123"/>*/}
                                {/*    <div className={a.WrapperName}>*/}
                                {/*        <p className={a.Name}>„Картина на заказ”</p>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                {/*<div className={a.BlockArt}>*/}
                                {/*    <img className={a.Art} src="/ArtStudio/anikv.jpeg" alt="123"/>*/}
                                {/*    <div className={a.WrapperName}>*/}
                                {/*        <p className={a.Name}>„ANIKV”</p>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                <div className={a.BlockArt}>
                                    <img className={a.Art} src="/ArtStudio/australia.jpeg" alt="123"/>
                                    <div className={a.WrapperName}>
                                        <p className={a.Name}>„Australia in Fair”</p>
                                    </div>
                                </div>
                                {/*<div className={a.BlockArt}>*/}
                                {/*    <img className={a.Art} src="/ArtStudio/bojeck.jpeg" alt="123"/>*/}
                                {/*    <div className={a.WrapperName}>*/}
                                {/*        <p className={a.Name}>„Secretariat”</p>*/}
                                {/*    </div>*/}
                                {/*</div>*/}

                                {/*<div className={a.BlockArt}>*/}
                                {/*    <img className={a.Art} src="/ArtStudio/home.jpeg" alt="123"/>*/}
                                {/*    <div className={a.WrapperName}>*/}
                                {/*        <p className={a.Name}>„Summertime”</p>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                <div className={a.BlockArt}>
                                    <img className={a.Art} src="/ArtStudio/tennis.jpeg" alt="123"/>
                                    <div className={a.WrapperName}>
                                        <p className={a.Name}>„В квартире у мистера подхвоста”</p>
                                    </div>
                                </div>
                                <LinkButton url={'https://t.me/innv1'} text={'Заказать картину'}/>
                            </div>
                        </section>
                    </Slide>
                </div>
                <Footer/>
            </div>
        );
    }
}

export default ArtStudio;
