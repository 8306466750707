import React from 'react'
import '../index.scss'

export const Line = ({color}) => {
    return (
        <div style={color} className="Line"></div>
    )
}

export default Line;
