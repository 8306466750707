import React from "react";
import './Burger.scss'
import {NavLink} from "react-router-dom";
import {push as Menu} from 'react-burger-menu'

class Burger extends React.Component {
    showSettings(event) {
        event.preventDefault();
    }

    render() {
        // NOTE: You also need to provide styles, see https://github.com/negomi/react-burger-menu#styling
        return (
            <Menu
                pageWrapId={"page-wrap"} outerContainerId={"outer-container"}
                // width={'25%'}
                right
            >
                {/*<div className="centr">*/}
                <NavLink target='_top' to='/DesignStudio' className="menu-item">Дизайн студия  &nbsp;</NavLink>
                <NavLink target="_blank" to='/ArtStudio' className="menu-item ">Арт Студия &nbsp; </NavLink>
                {/*<NavLink to='/Juniorproject' className="menu-item">JUNIOR project  &nbsp;</NavLink>*/}
                <NavLink target="_parent" to='/NewDesigner' className="menu-item Designer ">Вакансии  &nbsp;</NavLink>
                {/*</div>*/}
            </Menu>
        );
    }
}

export default Burger;
