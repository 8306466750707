import React from 'react';
import PropTypes from 'prop-types';

const Badge = ({ text, color, backgroundColor }) => {
    const badgeStyle = {
        height: 'auto',
        width: 'auto',
        padding: '7px 12px',
        borderRadius: '15px',
        color: color || '#026AA2',
        backgroundColor: backgroundColor || '#F0F9FF',
        fontSize: '15px',
        fontWeight: '500',
    };

    return <span style={badgeStyle}>{text}</span>;
};

Badge.propTypes = {
    text: PropTypes.string.isRequired,
    color: PropTypes.string,
    backgroundColor: PropTypes.string,
};

export default Badge;