import React from 'react'
import k from './Kaspersky.module.scss'
import {Slide} from "react-reveal";
import Title from "../../../Components/Title/Title";
import {Helmet} from "react-helmet";
import Footer from "../../../Components/Footer/Footer";

export const Kaspersky = () => {

    const color = {
        color: 'white'
    }
    const Figma = {
        borderRadius: '18px',
        border: 'none',
        width: '100%',
        height: '100%'
    }

    return (
        <div className={k.MainWrapper}>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Kaspersky</title>
            </Helmet>

            {/*<NavigationBar*/}
            {/*    FirstNumberLink={'/vk-intership-case'}*/}
            {/*    SecondNumberLink={'/farsh-up2u-case'}*/}
            {/*    LeftNumber={'<- 000'}*/}
            {/*    Now={'001'}*/}
            {/*    RightNumber={'002 ->'}*/}
            {/*/>*/}

            <div id='start' className={k.FirstBlock}>
                <Slide duration={1500} bottom>
                    <div className={k.BlockForTitle}>
                        <Title
                            titleStyle={color}
                            TitleBig='Проектировка интерфейса живой очереди для сотрудников банка'
                        />
                    </div>
                </Slide>
            </div>

            {/* <div className={k.SecondBlockWrapper}>
                <div className={k.SecondBlock}>
                    <div className={k.ScreensWrapper}>
                        <img className={k.ImgStyle} src="/Kaspersky/sce.svg" alt=""/>
                        <img className={k.ImgStyle} src="/Kaspersky/str.svg" alt=""/>
                        <img className={k.ImgStyle} src="/Kaspersky/work.png" alt=""/>
                        <img className={k.ImgStyle} src="/Kaspersky/work2.png" alt=""/>
                        <img className={k.ImgStyle} src="/Kaspersky/wait.png" alt=""/>
                    </div>
                </div>
            </div> */}

            {/*Figma*/}
            <section className={k.WrapperForFigma}>
                <div className={k.FigmaExport}>

                    <div className={k.Title}>
                        <Title
                            TitleBig='Макет из Figma, welcome'
                        />
                    </div>

                    <iframe style={Figma}
                            title={'Kaspersky'}
                            src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FmaMKgtFNXw5Vc4D4BSXAn4%2FKaspersky-Lab%3Fnode-id%3D199%253A5633%26t%3D4Xvguychs1yJzr8e-1"
                            allowFullScreen>
                    </iframe>
                </div>
            </section>

            <footer>
                <Footer/>
            </footer>
        </div>
    )
}

export default Kaspersky;
