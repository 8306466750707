import {React, useRef} from 'react'
import h from './AllWorks.module.scss'
import Title from "../../../Components/Title/Title";
import useSWR from "swr";
import Cosmic from "cosmicjs";
import NotFound from "../../404Page/NotFound";
import a from "../../../Components/Case/Case.module.scss";
import Badge from "../../../Components/Badge/Badge";
import LinkButton from "../../../Components/Buttons/LinkButton/LinkButton";

export const AllWorks = () => {

    //Comsic CMS
    const api = Cosmic();

// Set these values, found in Bucket > Settings after logging in at https://app.cosmicjs.com/login
    const bucket = api.bucket({
        slug: "artsolovev-artsolovev",
        read_key: "RTtb8Jd3In7PVBXlcraVzDFBUQual8aK3Ex3HAE9Z9MLinuaJl",
    });

    const fetchPosts = async () => {
        const data = await bucket.objects
            .find({
                type: 'allworks',
            })
            .props("slug,title,content,metadata"); // Limit the API response data by props
        return data;
    };

    const {data, error} = useSWR("fetch-posts", fetchPosts); // Use SWR hook
    if (!data) return <NotFound/>
    const allworks = data.objects;

    return (
        <div className={h.Wrapper}>
            {/*<header id='start' className={h.Header}>*/}
            {/*    /!*TITLE*!/*/}
            {/*    <div className={h.WrapperContent}>*/}
            {/*        <h1>WORK IN PROGRESS</h1>*/}
            {/*    </div>*/}
            {/*</header>*/}

            {/*Остальные кейсы CMS*/}
            {/*<div id='freelanceBlock' className={h.BlockWrapper}>*/}
            {/*    {allworks.map((cases) => (*/}
            {/*        <Slide bottom>*/}
            {/*            <div key={cases.slug} className={h.Block}>*/}
            {/*                <div className={h.ImageWrapper}>*/}
            {/*                    <img data-value="1" className={h.ImgStyle} src={cases.metadata.image.imgix_url}*/}
            {/*                         alt="image"/>*/}
            {/*                </div>*/}
            {/*                <div className={h.Title}>*/}
            {/*                    <a target='_blank' href={cases.metadata.link}>*/}
            {/*                        <h1>{cases.title}</h1>*/}
            {/*                    </a>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </Slide>*/}
            {/*    ))}*/}
            {/*</div>*/}

            <div id='freelanceBlock' className={h.BlockWrapper}>
                {/*ИНФОРКАРШ*/}
                <div>
                    <div className={h.InfoCard}>
                        <div className={h.ImageWrapper}>
                            <img src="/carshare.webp" alt="carshare"/>
                        </div>
                        <Title
                            title='Промо-сайт с информацией о каршеринге'
                        />
                        <div className={h.BadgeWrapper}>
                            <Badge text={'Дизайн'}/>
                            <Badge text={'Разработка'}/>
                            <Badge text={'react.js'}/>
                        </div>
                    </div>
                    <LinkButton
                        url={'https://www.carsharepromo.ru'}
                        text={'Посмотреть сайт'}/>
                </div>
                {/*KURTSMOKE*/}
                <div>
                    <div className={h.InfoCard}>
                        <div className={h.ImageWrapper}>
                            <img src="/kurtsmoke.webp" alt="kurtsmoke"/>
                        </div>
                        <Title
                            title='KURTSMOKE'
                        />
                        <div className={h.BadgeWrapper}>
                            <Badge text={'Дизайн'}/>
                            <Badge text={'Разработка'}/>
                            <Badge text={'react.js'}/>
                        </div>
                    </div>
                    <LinkButton
                        url={'https://www.kurtsmoke.ru'}
                        text={'Посмотреть сайт'}/>
                </div>

                <div>
                    <div className={h.InfoCard}>
                        <div className={h.ImageWrapper}>
                            <img src="/UREMONT.webp" alt="UREMONT"/>
                        </div>
                        <Title
                            title='UREMONT'
                        />
                        <div className={h.BadgeWrapper}>
                            <Badge text={'Дизайн'}/>
                            <Badge text={'Разработка'}/>
                            <Badge text={'Tilda'}/>
                        </div>
                    </div>
                    <LinkButton
                        url={'https://agentrace.uremont.com'}
                        text={'Посмотреть сайт'}/>
                </div>

                {/*ANNA LIONESS*/}
                <div>
                    <div className={h.InfoCard}>
                        <div className={h.ImageWrapper}>
                            <img src="/AnnaLionesss.webp" alt="AnnaLionesss"/>
                        </div>
                        <Title
                            title='Сайт для фотографа'
                        />
                        <div className={h.BadgeWrapper}>
                            <Badge text={'Дизайн'}/>
                            <Badge text={'Разработка'}/>
                            <Badge text={'Tilda'}/>
                            <Badge text={'SEO'}/>
                        </div>
                    </div>
                    <LinkButton
                        url={'https://lionessspho.ru/'}
                        text={'Посмотреть сайт'}/>
                </div>

                {/*AUTOKLONDIKE*/}
                <div>
                    <div className={h.InfoCard}>
                        <div className={h.ImageWrapper}>
                            <img src="/avto.png" alt="AUTOKLONDIKE"/>
                        </div>
                        <Title
                            title='AUTOKLONDIKE'
                        />
                        <div className={h.BadgeWrapper}>
                            <Badge text={'Дизайн'}/>
                            <Badge text={'Разработка'}/>
                            <Badge text={'Tilda'}/>
                        </div>
                    </div>
                    <LinkButton
                        url={'http://autoklondike.tilda.ws/'}
                        text={'Посмотреть сайт'}/>
                </div>

                {/*OK789 BRAND*/}
                <div>
                    <div className={h.InfoCard}>
                        <div className={h.ImageWrapper}>
                            <img src="/ok789.webp" alt="ok789"/>
                        </div>
                        <Title
                            title='OK789 BRAND'
                        />
                        <div className={h.BadgeWrapper}>
                            <Badge text={'Разработка'}/>
                            <Badge text={'Tilda'}/>
                            <Badge text={'SEO'}/>
                        </div>
                    </div>
                    <LinkButton
                        url={'https://ok789.ru/'}
                        text={'Посмотреть сайт'}/>
                </div>
            </div>
        </div>
    )
}

export default AllWorks;
