import React from 'react';
import {Helmet} from "react-helmet";
import vk from './VkInternship.module.scss'
import Title from "../../../Components/Title/Title";
import {Slide} from 'react-reveal'
import Footer from "../../../Components/Footer/Footer";

export const VkInternship = () => {
    const Figma = {
        borderRadius: '18px',
        border: 'none',
        width: '100%',
        height: '100%'
    }
    return (
        <div className={vk.MainWrapper}>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>VK TEAM</title>
            </Helmet>
            {/*<header></header>*/}
            <div id='start' className={vk.FirstBlock}>
                <Slide duration={1500} right>
                    <div className={vk.BlockForTitle}>
                        <h1 className={vk.NameTitle}>Интеграция новой фичи для продуктовой команды VK</h1>
                        <h2 className={vk.NameTitle}>*Проект выполнен в качестве тестового задания*</h2>
                    </div>
                </Slide>
            </div>

            {/*Задача*/}
            <section className={vk.VkSection}>
                <div className={vk.SecondBlock}>
                    <Slide left>
                        <div className={vk.WrapperTask}>
                            <div className={vk.TaskBlock}>
                                <h1 className={vk.NameTitle}>Вводные данные</h1>
                                <span className={vk.MainText}>
                                Люди публикуют на стене ВКонтакте самый разный контент: красивые фотографии, свои мысли, любимые песни, интересные новости. Это помогает им не только самовыражаться, но и оставаться на связи с друзьями. Но современные тренды задают высокую планку качества публикуемого контента, поэтому делать посты становится сложнее. <br/>
                                <br/>
А что если мы покажем людям, что оставаться на связи с друзьями и быть активным в соцсети — это просто? Давайте подумаем над исчезающим форматом «неидеального» постинга, чтобы человек мог проще поделиться моментами из жизни, своими мыслями или просто повеселиться с друзьями. Это может быть голосовой пост, фотография в моменте, эмодзи или стикер, короткая фраза, мини интерактив с друзьями или любой другой фан. <br/>
<br/>
                            </span>
                            </div>
                        </div>
                    </Slide>
                    <Slide right>
                        <div className={vk.WrapperTask}>
                            <div className={vk.TaskBlock}>
                                <h1 className={vk.NameTitle}>Задача</h1>
                                <span className={vk.MainText}>

Спроектировать функциональность
исчезающего поста-бомбочки для платформы  IOS и показать сценарий с разных сторон. <br/>
<br/>
— Точку входа и экраны создания поста-бомбочки для автора; <br/>
— Отображение поста-бомбочки в ленте или профиле человека; <br/>
— Взаимодействие друзей и подписчиков с таким постом.
                                    </span>
                                <div className={vk.IlBlock}>
                                    <img className={vk.IllustrationTask} src="/VkAssets/il.png" alt=""/>
                                </div>
                            </div>
                        </div>
                    </Slide>
                </div>
                {/*<Line/>*/}
            </section>

            {/*Этапы работы*/}
            <section className={vk.VkSection}>
                <div className={vk.WrapperInfo}>
                    <Slide left>
                        <div className={vk.InfoBlock}>
                            <h1 className={vk.NameTitle}>Этапы работы</h1>
                        </div>
                    </Slide>
                    <Slide right>
                        <div className={vk.WorkExp}>
                            <img className={vk.ImgMaket} src="/VkAssets/work.png" alt="work"/>
                        </div>
                        <div className={vk.WorkExpMobile}>
                            <Title
                                title='Первый этап'
                                subtitle={['Общий анализ', <br/>, 'Подготовка к работе',
                                    <br/>, 'Основные экраны']}
                            />
                            <Title
                                title='Второй этап'
                                subtitle='Анализ функции'
                            />
                            <Title
                                title='Третий этап'
                                subtitle='Реализация функции'
                            />
                        </div>
                    </Slide>
                </div>
            </section>

            {/*About UI-KIT*/}
            <section className={vk.VkSection}>
                <div className={vk.WrapperUIKIT}>
                    <Slide left>
                        <div className={vk.WrapperTextUIKIT}>
                            <h1 className={vk.TextUIKIT}>
                                В процессе проектирования был использован UI Kit команды Вконтакте,
                                а так же было отрисовано большое количество компонентов не опубликованных в
                                public acses
                            </h1>
                        </div>
                    </Slide>
                    <Slide right>
                        <div className={vk.BackgroundUIKIT}/>
                    </Slide>
                </div>
            </section>

            {/*Mockups*/}
            <section className={vk.VkSection}>
                <div className={vk.FourthBlock}>
                    <div className={vk.Mockups}>
                        <Slide bottom>
                            <div className={vk.ImgInfo}>
                                <img className={vk.ImgMaket} src="/VkAssets/Main.png" alt=""/>
                            </div>
                        </Slide>
                    </div>
                    <div className={vk.Mockups}>
                        <Slide left>
                            <img className={vk.ImgMockups} src="/VkAssets/1.png" alt=""/>
                        </Slide>
                        <h1>→</h1>
                        <Slide bottom>
                            <img className={vk.ImgMockups} src="/VkAssets/2.png" alt=""/>
                        </Slide>
                        <h1>→</h1>
                        <Slide right>
                            <img className={vk.ImgMockups} src="/VkAssets/3.png" alt=""/>
                        </Slide>
                    </div>
                    <div className={vk.Mockups}>
                        <Slide left>
                            <img className={vk.ImgMockups} src="/VkAssets/4.png" alt=""/>
                        </Slide>
                        <h1>→</h1>
                        <Slide bottom>
                            <img className={vk.ImgMockups} src="/VkAssets/5.png" alt=""/>
                        </Slide>
                        <h1>→</h1>
                        <Slide right>
                            <img className={vk.ImgMockups} src="/VkAssets/6.png" alt=""/>
                        </Slide>
                    </div>
                </div>
            </section>

            {/*Video*/}
                <section className={vk.VkSection}>
                    <div className={vk.FifthBlock}>
                        <div className={vk.WrapperTitle}>
                            <Slide right duration={4000}>
                                <h1 className={vk.BackgraundTitle}>PREVIEW</h1>
                            </Slide>
                        </div>

                        <div className={vk.WrapperVideo}>
                            <video muted autoPlay loop src="/VkAssets/vk.mp4"/>
                        </div>
                    </div>
                </section>

            {/*Figma*/}
                <section className={vk.WrapperForFigma}>
                    <div className={vk.FigmaExport}>

                        <div className={vk.Title}>
                            <Title
                                TitleBig='Макет из Figma, welcome'
                            />
                        </div>

                        <iframe style={Figma}
                                title={'VK'}
                                src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FbQc6hF762cu4F5E38fy7LY%2FVK%3Fnode-id%3D0%253A1"
                                allowFullScreen>
                        </iframe>

                    </div>
                </section>
            <footer>
                <Footer/>
            </footer>
        </div>
    )
}

export default VkInternship;
