import React from 'react'
import ff from './Farsh.module.scss'
import Title from "../../../Components/Title/Title";
import {Slide, Fade} from 'react-reveal'
import Footer from "../../../Components/Footer/Footer";
import {Link} from "react-scroll";
import ReactTooltip from "react-tooltip";
import {Helmet} from "react-helmet";
import a from "../../Resume/Resume.module.scss";

export const Farsh = () => {

    const Color = {
        color: '#fff',
        display: 'flex',
        justifyContent: 'center',
    }

    const ColorBlack = {
        color: 'black',
    }

    return (
        <Fade>
            <div className={ff.Wrapper}>

                <Helmet>
                    <meta charSet="utf-8"/>
                    <title>FARSHxUP2U</title>
                </Helmet>

                <ReactTooltip
                    offset={{top: 30}}
                    effect={"solid"}
                    textColor={'#fff'}
                    backgroundColor={'#171717'}
                    delayHide={100}
                    type={"success"}/>

                <header className={ff.FirstBlock} id='start'>
                    <img className={ff.video} src="/Farsh/logos.gif"/>
                    {/*<div className={ff.TitleWrapper}>*/}
                    {/*    <Title*/}
                    {/*        title='FARSH x UP2U'*/}
                    {/*        subtitle='USER STORY FOR TELEGRAM BOT'*/}
                    {/*    />*/}
                    {/*</div>*/}
                </header>

                <section className={ff.SecondBlock}>
                    <div className={ff.WrapperSecondBlock}>
                        <Slide left>
                            <div className={ff.LeftSide}>
                                <Slide left>
                                    <div className={ff.TextBlock}>
                                        <a className={ff.AboutCompanyText} rel="noreferrer" target='_blank'
                                           href="https://farshburger.ru/">
                                            #FARШ - Сеть бургерных Аркадия Новикова @novikovgroup и «Мираторга»
                                        </a>
                                        <a className={ff.AboutCompanyText} rel="noreferrer" target='_blank'
                                           href="https://up2u-app.tilda.ws/">
                                            UP2U - онлайн-продажи
                                            с помощью мини-приложений в Телеграме
                                        </a>
                                    </div>
                                </Slide>

                                <div className={ff.MockUp}>
                                    <img className={ff.ImgMockup} src="/Farsh/mockup.png" alt=""/>
                                </div>
                            </div>
                        </Slide>

                        <Slide right>
                            <div className={ff.RightSide}>
                                <img className={ff.Img} src="/Farsh/bg.jpg" alt=""/>
                            </div>
                        </Slide>
                    </div>
                </section>

                {/*<section className={ff.ThirdBlock}>*/}
                {/*    <div className={ff.WrapperThithBlock}>*/}
                {/*        <Title title='Задачи'/>*/}
                {/*    </div>*/}
                {/*</section>*/}

                <section className={ff.FourBlock}>
                    <div className={ff.WrapperFourBlock}>
                        <div className={ff.AllMockUps}>
                            <Slide right>
                                <div className={ff.Title}>
                                    <Title
                                        titleStyle={ColorBlack}
                                        TitleBig='Задачей было наглядно показать
                             разработчикам все сценарии, которые проходит пользователь'
                                        title='Поэтому тут будет немного скринов'
                                        subtitle={<Link className={'cursorhover'} smooth spy to="video">
                                            – но лучше посмотреть видео </Link>}/>
                                </div>
                            </Slide>

                            {/*<div className={ff.WrapperBtn}>*/}
                            {/*    <Link*/}
                            {/*        className={'cursorhover'}*/}
                            {/*        smooth spy to="video">*/}
                            {/*        <StickyButton*/}
                            {/*            title='Пропустить скриншоты'*/}
                            {/*        />*/}
                            {/*    </Link>*/}
                            {/*</div>*/}

                            <img className={ff.img} src="/Farsh/all2.png" alt=""/>

                            <div id='video' className={ff.WrapperVideo}>
                                <Slide right>
                                    <div className={ff.VideoMockUp}>
                                        <video muted loop autoPlay className={ff.Video} src="/MAESTRELLO.mp4"/>
                                    </div>
                                </Slide>
                            </div>
                        </div>
                    </div>
                </section>

                <section className={ff.FiveBlock}>
                    <div className={ff.WrapperFiveBlock}>
                        <Title
                            title='Заказать еды можно здесь'
                            subtitle='FARШ'
                            titleStyle={Color}
                        />
                        <div className={'cursorhover'}>
                            <div className={ff.Promo}
                                 data-tip='Приятного аппетита!'
                                 data-offset="{'bottom': 15}"
                                 data-place='bottom'
                            >
                                <a className={ff.LinkPromo} rel="noreferrer" target="_blank"
                                   href="https://t.me/farsh_burger_bot?start=invite271321007">
                                    <h1>Перейти в telegram</h1>
                                </a>
                            </div>
                        </div>
                    </div>
                </section>

                <footer>
                    <Footer/>
                </footer>
            </div>
        </Fade>
    )
}

export default Farsh;
