import './App.scss';
import 'custom-cursor-react/dist/index.css';
import React from "react";
import Resume from "./Pages/Resume/Resume";
import NotFound from "./Pages/404Page/NotFound";
import ArtStudio from "./Pages/ArtStudio/ArtStudio";
import DesignStudio from "./Pages/DesignStudio/DesignStudio";
import VkInternship from "./Pages/Cases/VKINTERNSHIP000/VkInternship";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import Farsh from "./Pages/Cases/FARSHxUP2U002/Farsh";
import CustomCursor from 'custom-cursor-react';
import AllWorks from "./Pages/Cases/AllWorks/AllWorks";
import a from "./Pages/Resume/Resume.module.scss";
import MusicButton from "./Components/Buttons/MusicButton/MusicButton";
import Kaspersky from "./Pages/Cases/Kaspersky001/Kaspersky";
import OpenBank from "./Pages/Cases/OpenBank004/OpenBank";

function App() {
    return (<div>
        <div className='Cursor'>
            <CustomCursor
                targets={['.link', '.cursorhover']}
                // customClass='custom-cursor'
                dimensions={33}
                fill='#080808'
                smoothness={{
                    movement: 1, scale: 0.7, opacity: 1.5,
                }}
                strokeWidth={1}
                strokeColor={'#5E81F4'}
                targetOpacity={0.5}
                targetScale={0.7}
            />
        </div>

        {/*Музыкальное сопровождение*/}
        <div className={a.StickButtonWrapper}>
            <MusicButton/>
        </div>

        {/*<div className='serv'>*/}
        {/*    <div className='pin'>*/}
        {/*        сайт находится в разработке :)*/}
        {/*    </div>*/}
        {/*</div>*/}

        <BrowserRouter>
            <Routes>
                {/*Pages*/}
                <Route path="" element={<DesignStudio/>}/>
                <Route path="*" element={<NotFound/>}/>
                <Route path="/Resume" element={<Resume/>}/>
                <Route path="/ArtStudio" element={<ArtStudio/>}/>
                <Route path="/DesignStudio" element={<DesignStudio/>}/>
                {/*Cases*/}
                <Route path="/vk-intership-case" element={<VkInternship/>}/>
                <Route path="/farsh-up2u-case" element={<Farsh/>}/>
                <Route path="/all-works" element={<AllWorks/>}/>
                <Route path="/kaspersky-case" element={<Kaspersky/>}/>
                <Route path="/open-bank-case" element={<OpenBank/>}/>
            </Routes>
        </BrowserRouter>
    </div>);
}

export default App;
