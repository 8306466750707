import React, {Component} from 'react';
import f from './Footer.module.scss'
import {Link} from "react-scroll";
import {Fade} from "react-reveal";
import {NavLink} from "react-router-dom";

class Footer extends Component {
    render() {
        return (
            <div className={f.Wrapper}>
                <div className={f.FooterWrapper}>
                    <Fade bottom>

                        <div className={f.WrapperCoin}>
                            <a href="https://t.me/innv1" target="_blank" className={f.Title}>Написать в телеграм</a>


                            {/*<div className={f.links}>*/}
                            {/*    <a className='cursorhover' rel="noreferrer" target='_blank' href="https://t.me/innv1">Telegram</a>*/}
                            {/*    <a className={'cursorhover'} rel="noreferrer" target='_blank' href="mailto:x.innv1@gmail.com">Gmail</a>*/}
                            {/*</div>*/}
                        </div>

                        <div className={f.Info}>
                            <h2 className={'cursorhover'}>Сделано с любовью к проектированию 💙</h2>
                            <h2>2024</h2>
                        </div>
                    </Fade>
                </div>
            </div>
        );
    }
}

export default Footer;
