import React, {useState} from 'react'
import ReactTooltip from "react-tooltip";
import a from './Resume.module.scss'
import {Fade, Slide} from "react-reveal";
import Title from "../../Components/Title/Title";
import Footer from "../../Components/Footer/Footer";
import Line from "../../Components/Line";
import Marquee from "react-fast-marquee";
import {Helmet} from "react-helmet";
import VkInternship from "../Cases/VKINTERNSHIP000/VkInternship";
import Kaspersky from "../Cases/Kaspersky001/Kaspersky";
import Farsh from "../Cases/FARSHxUP2U002/Farsh";
import AllWorks from "../Cases/AllWorks/AllWorks";
import OpenBank from "../Cases/OpenBank004/OpenBank";
import Case from "../../Components/Case/Case";
import DownLoadPdf from "../../Components/Buttons/DownLoadPDF/DownLoadPDF";
import Subtitle, {TitleNoPadding} from "../../Components/Title/Subtitle";

export const Resume = () => {
    const [isMaxWidth, setIsMaxWidth] = useState(true);
    const toggleWidth = () => {
        setIsMaxWidth((prevIsMaxWidth) => !prevIsMaxWidth);
    };

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Резюме</title>
                <link rel="preconnect" href="https://artsolovev.ru/resume"/>
                <meta name="description"
                      content="Product designer with 3+ year experience"/>
                <meta property="og:description" content="Product designer with 3+ year experience"/>
            </Helmet>
            <div className={`${a.Wrapper} ${isMaxWidth ? a.maxWidth : a.autoWidth}`}>
                {/*Подсказки*/}
                <button className={a.WidthButton} onClick={toggleWidth}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M8 3V6C8 6.53043 7.78929 7.03914 7.41421 7.41421C7.03914 7.78929 6.53043 8 6 8H3M21 8H18C17.4696 8 16.9609 7.78929 16.5858 7.41421C16.2107 7.03914 16 6.53043 16 6V3M16 21V18C16 17.4696 16.2107 16.9609 16.5858 16.5858C16.9609 16.2107 17.4696 16 18 16H21M3 16H6C6.53043 16 7.03914 16.2107 7.41421 16.5858C7.78929 16.9609 8 17.4696 8 18V21" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </button>
                <div className={a.ReactTooltip}>
                    <ReactTooltip
                        offset={{top: 30}}
                        effect={"solid"}
                        textColor={'#fff'}
                        backgroundColor={'#171717'}
                        // border={true}
                        delayHide={100}
                        type={"success"}/>
                </div>

                <header className={a.header} id='start'>
                    <Fade>
                        <div className={a.FirstBlock}>
                            <Slide duration={2000} bottom>
                                <div className={a.AvatarWrapper}>
                                    <div className={a.Avatar}/>
                                    <div className={a.About}>
                                        <h1 className={a.text}>
                                            <Title
                                                TitleBig='Соловьев Александр'
                                                title='Product designer, 22 года, Россия, Москва'
                                            />
                                            {/*В поиске интересного продукта и крутой команды*/}
                                            {/*<br/>*/}
                                            {/*<a className={a.HideText}*/}
                                            {/*   rel="noreferrer"*/}
                                            {/*   target={'_blank'}*/}
                                            {/*   href="https://t.me/innv1">*/}
                                            {/*    vk*/}
                                            {/*</a>*/}
                                            {/*        <span className={a.HideText}> <br/>*/}
                                            {/*        Мои хобби: <br/>*/}
                                            {/*В свободное время веду активный образ жизни. <br/>*/}
                                            {/*    Играю в Большой теннис 🎾 & Пинг-понг 🏓 Шахматы ♟ <br/>*/}
                                            {/*    участвую в велопробегах 🚴🏻‍ & хожу в зал 💪🏻*/}
                                            {/*    Совмещаю чтение книг и просмотр фильмов с изучением <br/>*/}
                                            {/*    английского языка*/}
                                            {/*</span>*/}
                                            <br/>
                                            <DownLoadPdf/>
                                        </h1>
                                    </div>
                                </div>
                            </Slide>
                        </div>
                    </Fade>
                </header>

                {/*БЕГУЩАЯ СТРОКА */}
                <section className={a.ScrollLineWrapper}>
                    <Slide duration={2000} right>
                        <div className={a.ScrollLine}>
                            <Marquee
                                pauseOnClick
                                speed={100}
                                gradient={false}
                            >
                                <div className={a.ScrollLineBlock}>
                                    <img className={a.ScrollLineItem} src="/Resume/Scroll/ux.png" alt="Ux"/>
                                    <h1 className={a.NameItem}>Pathway</h1>
                                </div>
                                <div className={a.ScrollLineBlock}>
                                    <img className={a.ScrollLineItem} src="/Resume/Scroll/Jira.png"
                                         alt="Jira"/>
                                    <h1 className={a.NameItem}>Jira</h1>
                                </div>
                                <div className={a.ScrollLineBlock}>
                                    <img className={a.ScrollLineItem} src="/Resume/Scroll/figma.png"
                                         alt="Figma"/>
                                    <h1 className={a.NameItem}>Figma</h1>
                                </div>
                                <div className={a.ScrollLineBlock}>
                                    <img className={a.ScrollLineItem} src="/Resume/Scroll/pixso.png"
                                         alt="Pixso"/>
                                    <h1 className={a.NameItem}>Pixso</h1>
                                </div>
                                <div className={a.ScrollLineBlock}>
                                    <img className={a.ScrollLineItem} src="/Resume/Scroll/notion.png"
                                         alt="notion"/>
                                    <h1 className={a.NameItem}>Notion</h1>
                                </div>
                                <div className={a.ScrollLineBlock}>
                                    <img className={a.ScrollLineItem} src="/Resume/Scroll/react.png"
                                         alt="react"/>
                                    <h1 className={a.NameItem}>React</h1>
                                </div>
                                <div className={a.ScrollLineBlock}>
                                    <img className={a.ScrollLineItem} src="/Resume/Scroll/jb_webstorm.png"
                                         alt="webstorm"/>
                                    <h1 className={a.NameItem}>Webstorm</h1>
                                </div>
                                <div className={a.ScrollLineBlock}>
                                    <img className={a.ScrollLineItem} src="/Resume/Scroll/tilda.png"
                                         alt="tilda"/>
                                    <h1 className={a.NameItem}>Tilda</h1>
                                </div>
                                <div className={a.ScrollLineBlock}>
                                    <img className={a.ScrollLineItem} src="/Resume/Scroll/behance.png"
                                         alt="behance"/>
                                    <h1 className={a.NameItem}>Behance</h1>
                                </div>
                                <div className={a.ScrollLineBlock}>
                                    <img className={a.ScrollLineItem}
                                         src="/Resume/Scroll/adobe_photoshop.png"
                                         alt="adobe_photoshop"/>
                                    <h1 className={a.NameItem}>Photoshop</h1>
                                </div>

                                <div className={a.ScrollLineBlock}>
                                    <img className={a.ScrollLineItem} src="/Resume/Scroll/npm.png"
                                         alt="npm"/>
                                    <h1 className={a.NameItem}>npm</h1>
                                </div>
                            </Marquee>
                        </div>
                    </Slide>
                </section>

                <div className={a.WorkAndEdu}>
                    {/*ОПЫТ РАБОТЫ */}
                    <section id='work' className={a.ThirdBlock}>
                        <Slide bottom>
                            <div className={a.WorkExpWrapper}>
                                <div className={a.TitleWrapper}>
                                    <Title
                                        TitleBig='Опыт работы 💪'
                                    />
                                </div>
                                <Line/>
                                <div className={a.WorkExp}>
                                    <div>
                                        <div className={`${a.ContentBlock} ${a.MarketLab}`}>
                                            {/*<img src="/tlogo.png" alt="Логотип"/>*/}
                                            <Title
                                                title={'MARKET LAB'}
                                                subtitle={'Product Designer'}
                                                description={'Октябрь 2023 — по настоящее время '}
                                            />
                                            <img className={a.Cat} src="/1.gif" alt="..."/>
                                            {/*<details open className={a.details}>*/}
                                            {/*    <summary className={a.sum}>*/}
                                            {/*        Подробнее*/}
                                            {/*    </summary>*/}
                                            {/*    <p>– Проектировал новые фичи и улучшал существующий функционал*/}
                                            {/*        приложения</p>*/}
                                            {/*    <p>– Проектировал интерактивные прототипы</p>*/}
                                            {/*    <p>– Проводил коридорные исследования</p>*/}
                                            {/*    <p>– Презентовал решения команде</p>*/}
                                            {/*    <p>– Проводил фронт-ревью</p>*/}
                                            {/*</details>*/}
                                            <a className='cursorhover' rel="noreferrer" target='_blank'
                                               href="https://mar-lab.ru">
                                                <div className={a.LinkWrapper}>
                                                    <div>mar-lab.ru</div>
                                                    <div>
                                                        🔗
                                                    </div>
                                                </div>
                                                <Line/>
                                            </a>
                                        </div>
                                    </div>

                                    <div>
                                        <div className={`${a.ContentBlock} ${a.MarketLab}`}>
                                            <Title
                                                title={'WAY FLARE'}
                                                subtitle={'Ведущий Product Designer'}
                                                description={'Март 2024 — по настоящее время '}
                                            />
                                            {/*<img className={a.Cat} src="/1.gif" alt="..."/>*/}
                                            <details open className={a.details}>
                                                <summary className={a.sum}>
                                                    Подробнее
                                                </summary>
                                                <p>Релиз: июнь 2024 г.</p>
                                            </details>
                                        </div>
                                    </div>

                                    <div>
                                        <div className={`${a.ContentBlock} ${a.MarketLab}`}>
                                            <Title
                                                title={'SPEECH'}
                                                subtitle={'Product Designer'}
                                                description={'Февраль 2024 — по настоящее время '}
                                            />
                                            <img className={a.Cat} src="/time.png" alt="..."/>

                                            <details open className={a.details}>
                                                <summary className={a.sum}>
                                                    Подробнее
                                                </summary>
                                                <p>Релиз: август 2024 г.</p>
                                            </details>
                                        </div>
                                    </div>


                                    <div>
                                        <div className={`${a.ContentBlock} ${a.Tinаkoff}`}>
                                            <Title
                                                title={'TINKOFF'}
                                                subtitle={'Product Designer'}
                                                description={'Апрель 2023 — Июль 2023'}
                                            />
                                            <details open className={a.details}>
                                                <summary className={a.sum}>
                                                   Подробнее
                                                </summary>
                                                <p>– Проектировал новые фичи и улучшал существующий функционал
                                                    приложения</p>
                                                <p>– Проектировал интерактивные прототипы</p>
                                                <p>– Проводил коридорные исследования</p>
                                                <p>– Презентовал решения команде</p>
                                                <p>– Проводил фронт-ревью</p>

                                                <a className='cursorhover' rel="noreferrer" target='_blank'
                                                   href="https://tinkoff.ru">
                                                    <div className={a.LinkWrapper}>
                                                        <div>tinkoff.ru</div>
                                                        <div>
                                                            🔗
                                                        </div>
                                                    </div>
                                                    <Line/>
                                                </a>
                                            </details>
                                        </div>
                                    </div>

                                    <div className={a.Five}>
                                        <div className={a.ContentBlock}>
                                            <Title
                                                title={'UP2U'}
                                                subtitle={'UX/UI Designer'}
                                                description={'2021—2022 г.'}
                                            />
                                            <details open className={a.details}>
                                                <summary className={a.sum}>
                                                    Подробнее
                                                </summary>
                                                <p>– Общение напрямую с заказчиком</p>
                                                <p>– Разработка User Story</p>
                                                <p>– Проводил UX исследования</p>
                                                <p>– Работа в команде</p>
                                                <p>– Разработка лендинга для компании</p>
                                                <p>– Взаимодействия с командой разработчиков</p>
                                                <p>– Проводил интервью с пользователями telegram бота</p>
                                                <a className='cursorhover' rel="noreferrer" target='_blank'
                                                   href="https://up2u-app.tilda.ws/#3">
                                                    <div className={a.LinkWrapper}>
                                                        <div>Сайт компании UP2U</div>
                                                        <div>
                                                            🔗
                                                        </div>
                                                    </div>
                                                    <Line/>
                                                </a>
                                                <a className='cursorhover' rel="noreferrer" target='_blank'
                                                   href="https://t.me/farsh_burger_bot">
                                                    <div className={a.LinkWrapper}>
                                                        <div>FARSH — telegram bot</div>
                                                        <div>
                                                            🔗
                                                        </div>
                                                    </div>
                                                    <Line/>
                                                </a>
                                            </details>
                                        </div>
                                    </div>

                                    <div className={a.One}>
                                        <div className={a.ContentBlock}>
                                            <Title
                                                title={'OKZO SYSTEMS'}
                                                subtitle={'Web developer'}
                                                description={'2020—2021 г.'}
                                            />
                                            <details open className={a.details}>
                                                <summary className={a.sum}>
                                                    Подробнее
                                                </summary>
                                                <p>
                                                    – Разработка фронтенда для интернет-магазинов <br/>
                                                    – Проектирование и разработка веб-интерфейсов. <br/>
                                                    <br/>

                                                    Работал с чужим кодом, который писали в разное время разные
                                                    программисты. Оптимизировал существующий код и запросы к базе,
                                                    дорабатывал функционал сайта. Из основного — сделал основной
                                                    функционал
                                                    приносящий доход. Подключил банковскую платежную систему.
                                                </p>
                                            </details>
                                        </div>
                                    </div>

                                    <div className={a.Two}>
                                        <div className={a.ContentBlock}>
                                            <Title
                                                title='BEAUTY BAY RUSSIA'
                                                subtitle={'Founder. Instagram-магазин'}
                                                description='2019 г. — по настоящее время'
                                            />
                                            <details className={a.details}>
                                                <summary className={a.sum}>
                                                    Подробнее
                                                </summary>
                                                <p>
                                                    – Ресейл товаров с зарубежных магазинов<br/>
                                                    – Ведение соц.сетей. SMM<br/>
                                                    – Работа с блогерами<br/>
                                                    – Ведение отчетности<br/>
                                                    – Монтаж видео <br/>
                                                    – Создание единой стилистики
                                                </p>
                                                <a className='cursorhover' rel="noreferrer" target='_blank'
                                                   href="https://instagram.com/beautybay.russia">
                                                    <div className={a.LinkWrapper}>
                                                        <div>@beautybay.russia — (VPN)</div>
                                                        <div>
                                                            🔗
                                                        </div>
                                                    </div>
                                                    <Line/>
                                                </a>
                                            </details>
                                        </div>
                                    </div>

                                    <div className={a.Three}>
                                        <div className={a.ContentBlock}>
                                            <Title
                                                title='MOVEON MOSCOW & SPACEX MOSCOW'
                                                subtitle={'Founder. Event-manager'}
                                                description='2018—2019 г. '
                                            />
                                            <details className={a.details}>
                                                <summary className={a.sum}>
                                                    Подробнее
                                                </summary>
                                                <p>
                                                    – Организация мероприятий <br/>
                                                    – Поиск площадок<br/>
                                                    – Заключение договоров с арендодателями<br/>
                                                    – Планирование бюджета & Закупки<br/>
                                                    – Организация и управление командой<br/>
                                                    – Работа с рекламой и продвижением Instagram-аккаунта<br/>
                                                </p>
                                                <a className='cursorhover' rel="noreferrer" target='_blank'
                                                   href="https://instagram.com/moveon.moscow">
                                                    <div className={a.LinkWrapper}>
                                                        <div>@moveon.moscow — (VPN)</div>
                                                        <div>
                                                            🔗
                                                        </div>
                                                    </div>
                                                    <Line/>
                                                </a>
                                                <a className='cursorhover' rel="noreferrer" target='_blank'
                                                   href="https://instagram.com/spacex.moscow">
                                                    <div className={a.LinkWrapper}>
                                                        <div>@spacex.moscow — (VPN)</div>
                                                        <div>
                                                            🔗
                                                        </div>
                                                    </div>
                                                    <Line/>
                                                </a>
                                            </details>
                                        </div>
                                    </div>

                                    <div className={a.Four}>
                                        <div className={a.ContentBlock}>
                                            <Title
                                                title={'FREELANCE'}
                                                subtitle={['Web-Developer, ', 'UI/UX Designer']}
                                                description='2017 г. — по настоящее время'
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Slide>
                    </section>
                    {/*ОБРАЗОВАНИЕ*/}
                    <section id='work' className={a.FourthBlock}>
                        <Slide bottom>
                            <div className={a.WorkExpWrapper}>
                                <Title
                                    TitleBig='Образование 🎓'
                                />
                                <Line/>
                                <div className={a.WorkExp}>
                                    <div>
                                        <div className={a.ContentBlock}>
                                            <Title
                                                title='Информационные системы и программирование'
                                                subtitle={'Колледж предпринимательства №11'}
                                                description={'Выпускник 2021 года'}
                                            />

                                            <TitleNoPadding
                                                title='Изобразительное искусство'
                                                subtitle='ГБУДО ДШИ №17'
                                                description='Сентябрь 2009 — Май 2016'
                                            />
                                        </div>
                                    </div>
                                    <div className={a.Three}>
                                        <div className={a.ContentBlock}>
                                            <Title
                                                title={['UX/UI Designer']}
                                                subtitle={'Contented'}
                                                description={'15.04.2022'}
                                            />
                                        </div>
                                        <div className={a.ContentBlock}>
                                            <Title
                                                title={['Business English']}
                                                subtitle={'ЦЗН Москва'}
                                                description='2 месяца'
                                            />
                                        </div>
                                    </div>
                                    <div className={a.Four}>
                                        <div className={a.ContentBlock}>
                                            <Title
                                                title='Активности и мероприятия:'
                                            />
                                            <TitleNoPadding
                                                subtitle={['Супер силы продуктового дизайнера']}
                                                description={['SberMarket Tech ', <br/>, '22.06.2023']}
                                            />
                                            <TitleNoPadding
                                                subtitle={['МТС Design Community Conf']}
                                                description={['МТС', <br/>, '16.06.2023']}
                                            />
                                            <TitleNoPadding
                                                subtitle={['Ozon Tech Community Design Meetup']}
                                                description={['OZON', <br/>, '17.02.2023']}
                                            />
                                            <TitleNoPadding
                                                subtitle={['FOUND IT']}
                                                description={['SBER', <br/>, '25.04.2022']}
                                            />
                                            <TitleNoPadding
                                                subtitle={['FFDD2D Design Conf']}
                                                description={['TINKOFF', <br/>, '31.03.2022']}
                                            />
                                            <TitleNoPadding
                                                subtitle={['PRAGMATICA']}
                                                description={[]}
                                            />
                                            <TitleNoPadding
                                                subtitle={['DESIGN LINE PRO']}
                                                description={['Недельные конференации']}
                                            />
                                            <TitleNoPadding
                                                subtitle={['Russian Design Cup']}
                                                description={['2021']}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Slide>
                    </section>
                    {/*КЕЙСЫ*/}
                </div>

                <div id='cases'/>

                <Case
                />

                <AllWorks/>
                <footer>
                    <Footer/>
                </footer>
            </div>
        </div>
    )
}

export default Resume;


// Скроол бар
// window.onscroll = function () {
//     myFunction();
// };
//
// function myFunction() {
//     let winScroll = document.body.scrollTop || document.documentElement.scrollTop;
//     let height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
//     let scrolled = winScroll / height * 100;
//     document.getElementById("myBar").style.width = scrolled + "%";
// }




