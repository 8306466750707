import React, {useState} from 'react'
import a from './Case.module.scss'
import b from '../../Pages/Resume/Resume.module.scss'
import Line from "../Line";
import Title from "../Title/Title";
import {Slide} from "react-reveal";
import Badge from "../Badge/Badge";
import LinkButton from "../Buttons/LinkButton/LinkButton";

import "react-image-gallery/styles/css/image-gallery.css";
import Gallery from "react-image-gallery";

import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';

export const Case = ({
                         Caseid, Company, Mission,
                         Date, HideVideo, HideImg, HideFigma, Discription,
                         Image1, Image2, BlockName,
                         BlockColor, Gifka, GifkaAlt, ONimgOFF,
                         Video, HideLogo, FigmaLink, figmaStyle, openModalPropsKaspersky,
                         openModalPropsVK, openModalPropsFarsh, openModalPropsFreelance,
                         openModalPropsOpenBank,
                         ...props
                     }) => {

    const VCBImage = [
        '/VCB.png',
        '/VCB2.png',
    ];

    return (
        <div>
            <div>
                <div className={a.CaseTitleWrapper}>
                    <Title TitleBig={'Рабочие кейсы'}/>
                    <Line/>
                </div>
                <section id='case' className={a.WrapperCase}>
                    {/*Perfomance Review Tinkoff Work*/}
                    <Slide bottom>
                        <div className={a.WrapperForCases2023}>
                            <img src="/review.webp" alt="tinkoff"/>
                            <div>
                                <div className={a.InfoCard}>
                                    <img src="/tlogo.png" alt="tinkoff"/>
                                    <Title title='Performance Review. Tinkoff Bank'
                                           description='Проектирование пользовательских интерфейсов для внутренних продуктов (B2E). Performance
                                        Review и ЭДО'
                                    />
                                    <details className={`${b.details}, ${a.detailBlock}`}>
                                        <summary className={b.sum}>
                                            Подробнее
                                        </summary>
                                        <br/>
                                        <b>Анализ:</b>
                                        <p>Провёл тщательный анализ существующего процесса оценки эффективности. Изучил
                                            бизнес-требования, потребности пользователей и выявил узкие места для
                                            улучшения.</p>
                                        <br/>
                                        <b>Оптимизация процесса:</b>
                                        <p>Внедрив новые дизайн-решения,
                                            я сфокусировался на сокращении времени, необходимого для выполнения задач и
                                            улучшении общей производительности пользователей.
                                            Мои решения помогли снизить нагрузку на пользователей и повысить качество их
                                            работы.</p>
                                        <br/>
                                        <b>Разработка пользовательских интерфейсов:</b>
                                        <p>Работая с командой, я создал интуитивные и привлекательные пользовательские
                                            интерфейсы
                                            для платформы «Performance Review»</p>
                                        <p>Проводил коридорные исследования и кастдевы с руководителями и топ-
                                            менеджерами для полного понимания потребностей ключевых стейкхолдеров.

                                            Взаимодействовал с разработчиками, обеспечивая гармоничное сотрудничество и
                                            эффективное воплощение дизайн-решений.</p>
                                        <br/>
                                        <p>Создавал интерактивные прототипы и проводил их тестирование
                                            с пользователями, используя полученные данные для оптимизации интерфейсов.


                                            Проводил опросы для глубокого понимания потребностей пользователей и
                                            получения ценных инсайтов.


                                            Принимал активное участие на командных демо, предлагая идеи
                                            и рекомендации по улучшению интерфейсов. Оказывал поддержку коллегам,
                                            помогая решать сложные задачи, проводя фронт-ревью и содействуя обмену
                                            опытом.
                                        </p>
                                    </details>
                                    <div className={a.BadgeWrapper}>
                                        <Badge text={'NDA'}/>
                                        <Badge text={'Дизайн'}/>
                                        <Badge text={'Исследования'}/>
                                        <Badge text={'Проектировка'}/>
                                        <Badge text={'Прототипы'}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Slide>

                    <Slide bottom>
                        <div className={a.WrapperForCases2023}>
                            <AwesomeSlider className={a.customAwesomeSlider} organicArrows={false}>
                                {VCBImage.map((image, index) => (
                                    <div key={index} data-src={image} />
                                ))}
                            </AwesomeSlider>

                            <div>
                                <div className={a.InfoCard}>
                                    <img src="/VCBLogo.png" alt="tinkoff"/>
                                    <Title title='VCB Parser'
                                           description='Сервис для отслеживания резких скачков коэффициентов'
                                    />
                                    <div className={a.BadgeWrapper}>
                                        <Badge text={'Дизайн'}/>
                                        <Badge text={'Проектировка'}/>
                                        <Badge text={'Прототипы'}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Slide>

                    {/*UP2U x Telegram*/}
                    <Slide bottom>
                        <div className={a.WrapperForCases2023}>
                            <div className={a.VideoMockUp}>
                                <video muted loop autoPlay className={a.Video} src="/MAESTRELLO.mp4"/>
                            </div>
                            <div>
                                <div className={a.InfoCard}>
                                    <img src="/tg.png" alt="tg x up2u"/>
                                    <Title title='Онлайн-продажи с помощью мини-приложений в телеграме'
                                           description='Спроектировал 3 user—карты телеграм—ботов для трех бизнесов'
                                    />
                                    <details className={`${b.details}, ${a.detailBlock}`}>
                                        <summary className={b.sum}>
                                            Подробнее
                                        </summary>
                                        <b>Описание проекта:</b>
                                        <p>На проекте в компании UP2U моей задачей было разработать и спроектировать
                                            user-map для пользователей телеграм ботов, которые предоставляли услуги и
                                            позволяли пользователям заказывать и оплачивать услуги внутри популярного
                                            мессенджера Telegram. В рамках этого проекта я работал над совершенно новой
                                            функциональностью, направленной на улучшение пользовательского опыта и
                                            повышение эффективности сервисов компании.</p>
                                        <br/>
                                        <b>Исследование пользовательских потребностей:</b>
                                        <p>Проводил интервью с различными группами пользователей телеграм ботов компании
                                            UP2U, чтобы лучше понять их ожидания, потребности и проблемы при
                                            использовании существующих сервисов. Анализировал полученные данные для
                                            выявления общих трендов и ключевых областей для улучшения.</p>
                                        <br/>
                                        <b>Создание user-map</b>
                                        <p>Разработал детальные user-map для пользователей телеграм ботов компании. Это
                                            включало визуализацию и анализ путей пользователей взаимодействия с ботами,
                                            определение узких мест и возможностей для оптимизации пользовательского
                                            опыта.</p>
                                        <br/>
                                        <b>Общение с основателем компании:</b>
                                        <p>Взаимодействовал напрямую с основателем компании UP2U, чтобы обсудить
                                            результаты исследования и предложить свои рекомендации по улучшению
                                            сервисов. Активно слушал его отзывы и вносил необходимые изменения в проект
                                            на основе его обратной связи.
                                        </p>
                                        <br/>
                                        <b>Результаты:</b>
                                        <p>В результате моей работы были разработаны мини-приложения внутри Telegram для
                                            компаний Farsh и Maestrello, которые значительно улучшили пользовательский
                                            опыт и привлекли новых клиентов. User-map способствовал более эффективной
                                            навигации пользователей в приложениях, сократил время на оформление заказов
                                            и оплату услуг, что в конечном итоге привело к увеличению выручки и
                                            удовлетворенности клиентов.</p>
                                        <br/>
                                        <b>Особенности проекта::</b>
                                        <p>Коммуникация с основателем: Прямое взаимодействие с основателем компании
                                            позволило мне лучше понять корпоративные цели и убедиться, что
                                            разрабатываемые решения соответствуют стратегии компании.
                                            Сфера Telegram ботов: Работа в области телеграм ботов представляла
                                            интересные вызовы и уникальные возможности для инноваций и разработки новых
                                            технологий.
                                        </p>
                                    </details>
                                    <div className={a.BadgeWrapper}>
                                        <Badge text={'Исследования'}/>
                                        <Badge text={'Проектировка'}/>
                                        <Badge text={'Прототипы'}/>
                                        <Badge text={'UX'}/>
                                        <Badge text={'Анализ пользовательских данных и требований'}/>
                                    </div>
                                </div>
                                <LinkButton url={'https://t.me/farsh_burger_bot?start=invite271321007'}
                                            text={'Посмотреть продукт'}/>
                            </div>
                        </div>
                    </Slide>

                    {/*ARTSOLOVEV*/}
                    <Slide bottom>
                        <div className={a.WrapperForCases2023}>
                            <img src="/me.png" alt="Artsolovev"/>
                            <div>
                                <div className={a.InfoCard}>
                                    <img src="/logo192.png" alt="Artsolovev"/>
                                    <Title
                                        title='Сайт студии и резюме'
                                        description='Cейчас вы на нем находитесь'
                                    />

                                    <div className={a.BadgeWrapper}>
                                        <Badge text={'Исследования'}/>
                                        <Badge text={'Дизайн'}/>
                                        <Badge text={'Разработка'}/>
                                    </div>
                                </div>
                                <LinkButton url={'https://artsolovev.ru'} text={'Сайт студии'}/>
                            </div>
                        </div>
                    </Slide>

                    {/*URDATE*/}
                    <Slide bottom>
                        <div className={a.WrapperForCases2023}>
                            <img src="/bg.gif" alt="urdate"/>
                            <div>
                                <div className={a.InfoCard}>
                                    <img src="/urdate.png" alt="urdate"/>
                                    <Title title='URDATE'
                                           description='Самостоятельно разработал полноценное MVP приложения для знакомств, используя
                                        ChatGTP. В планах довести приложения до релиза до конца 2023 г.'
                                    />

                                    <div className={a.BadgeWrapper}>
                                        <Badge text={'Дизайн'}/>
                                        <Badge text={'Разработка'}/>
                                    </div>
                                </div>
                                <LinkButton url={'https://urdate.ru'} text={'Открыть приложение'}/>
                            </div>
                        </div>
                    </Slide>
                </section>
            </div>

            <div>
                <div className={a.CaseTitleWrapper}>
                    <Title TitleBig={'2023'}/>
                    <Line/>
                </div>
                <section id='case' className={a.WrapperCase}>

                    {/*Тинькофф тестовое задание*/}
                    <Slide bottom>
                        <div className={a.WrapperForCases2023}>
                            <img src="/tinkoff.webp" alt="tinkoff"/>
                            <div>
                                <div className={a.InfoCard}>
                                    <img src="/tlogo.png" alt="tinkoff"/>
                                    <Title
                                        title='Интерфейс для операторов call-центра'
                                        // description={[        <br/>,
                                        //     'Процесс работы:', <br/>,
                                        //     '1. Research и поиск рефов', <br/>,
                                        //     '2. Поиск респондентов и интервью', <br/>,
                                        //     '3. Design. Первая итерация', <br/>,
                                        //     '4. Interview and Design check', <br/>,
                                        //     '5. Redesign. Вторая итерация', <br/>,
                                        //     '6. Interview and Design check', <br/>,
                                        //     '7. Done'
                                        // ]}
                                    />
                                </div>
                                <LinkButton
                                    url={'https://www.figma.com/file/zwZU6mrf2qjE4yzPWGEItc/%D0%98%D0%BD%D1%82%D0%B5%D1%80%D1%84%D0%B5%D0%B9%D1%81-%D0%9E%D0%BF%D0%B5%D1%80%D0%B0%D1%82%D0%BE%D1%80%D0%B0-%D1%81%D0%B2%D1%8F%D0%B7%D0%B8.-%D0%A2%D0%B8%D0%BD%D1%8C%D0%BA%D0%BE%D1%84%D1%84-%D0%A2%D0%B5%D1%81%D1%82%D0%BE%D0%B2%D0%BE%D0%B5-%D0%B7%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D0%B5?type=design&node-id=0%3A1&mode=design&t=GYtASrbyEfH6ELu9-1'}
                                    text={'Посмотреть проект'}/>
                            </div>
                        </div>
                    </Slide>

                    {/*VK тестовое задание*/}
                    <Slide bottom>
                        <div className={a.WrapperForCases2023}>
                            <img src="/vk.webp" alt="Vk"/>
                            <div>
                                <div className={a.InfoCard}>
                                    <img src="/VkAssets/Logo.png" alt="k"/>
                                    <Title
                                        title='VK.Маркет'
                                        description='Два больших тестовых задания за 2022-2023г.'
                                    />
                                    <div className={a.BadgeWrapper}>
                                        <Badge text={'Дизайн'}/>
                                        <Badge text={'Прототипы'}/>
                                        <Badge text={'Качественные исследование'}/>
                                    </div>
                                </div>
                                <LinkButton
                                    url={'https://www.figma.com/file/gsv3nrM0rFsqxKM3OI0UbD/%D0%A1%D0%BE%D0%BB%D0%BE%D0%B2%D1%8C%D0%B5%D0%B2-%D0%90%D0%BB%D0%B5%D0%BA%D1%81%D0%B0%D0%BD%D0%B4%D1%80----UX-%D0%94%D0%B8%D0%B7%D0%B0%D0%B9%D0%BD%D0%B5%D1%80---%D0%A2%D0%B5%D1%81%D1%82%D0%BE%D0%B2%D0%BE%D0%B5-%D0%B7%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D0%B5---25.05.2022---%D0%91%D0%B0%D0%BD%D0%BA-%D0%9E%D1%82%D0%BA%D1%80%D1%8B%D1%82%D0%B8%D0%B5?type=design&node-id=0%3A1&mode=design&t=ds0vtL0PqDEWKniR-1'}
                                    text={'Посмотреть проект'}/>
                            </div>
                        </div>
                    </Slide>

                    {/*Касперский тестовое задание*/}
                    <Slide bottom>
                        <div className={a.WrapperForCases2023}>
                            <img src="/kasper.webp" alt="kaspersky"/>
                            <div>
                                <div className={a.InfoCard}>
                                    <img src="/k.png" alt="k"/>
                                    <Title
                                        title='Интерфейс электронной очереди для банка'
                                    />
                                    <details className={`${b.details}, ${a.detailBlock}`}>
                                        <summary className={b.sum}>
                                            Подробнее
                                        </summary>
                                        <b>Исследование и Анализ:</b>
                                        <p>На начальном этапе проектирования, я тщательно изучил существующие системы
                                            электронной очереди в банках и провел анализ их функциональности. Это
                                            помогло мне выявить сильные стороны и проблемные моменты, которые можно было
                                            бы улучшить. Кроме того, я беседовал с сотрудниками банка и клиентами, чтобы
                                            полностью понять потребности и ожидания менеджеров в отношении нового
                                            интерфейса.</p>
                                        <br/>
                                        <b>Понимание Пользовательских Потребностей:</b>
                                        <p>Изучение требований менеджеров играло ключевую роль в проектировании
                                            интерфейса. Я провел интервью с менеджерами и выяснил, какие функции им
                                            необходимы для оптимизации их рабочего процесса. Также, учитывая их обратную
                                            связь и пожелания, я выделил наиболее важные элементы, которые помогут им
                                            более эффективно работать с системой электронной очереди.</p>
                                        <br/>
                                        <b>Создание Пользовательских Сценариев:</b>
                                        <p>Основываясь на понимании потребностей менеджеров, я разработал
                                            пользовательские сценарии, которые отражают их ежедневную работу с
                                            интерфейсом. Это позволило представить, как система должна взаимодействовать
                                            с менеджерами в различных ситуациях и как обеспечить интуитивную навигацию и
                                            простоту использования.</p>
                                        <br/>
                                        <b>Прототипирование Интерфейса:</b>
                                        <p>Создание прототипа было неотъемлемой частью проектирования. Используя
                                            пользовательские сценарии, я разработал прототип, который демонстрирует
                                            основные функции и взаимодействие с интерфейсом. Этот этап позволил
                                            сотрудникам и менеджерам банка оценить предполагаемую функциональность и
                                            внешний вид системы, а также дал возможность собрать обратную связь для
                                            улучшения интерфейса.</p>
                                        <br/>
                                        <b>Тестирование Прототипа:</b>
                                        <p>Тестирование прототипа было важным шагом в разработке интерфейса. Я провел
                                            тестирование с участием реальных пользователей, включая менеджеров, чтобы
                                            получить реальную обратную связь от тех, кто будет использовать систему
                                            ежедневно. Это позволило выявить слабые места и внести необходимые улучшения
                                            до окончательной реализации.</p>
                                    </details>
                                    <div className={a.BadgeWrapper}>
                                        <Badge text={'Исследования'}/>
                                        <Badge text={'Дизайн'}/>
                                        <Badge text={'Прототипы'}/>
                                    </div>
                                </div>
                                <LinkButton
                                    url={'https://www.figma.com/file/maMKgtFNXw5Vc4D4BSXAn4/Kaspersky-Lab?type=design&node-id=920%3A5278&mode=design&t=HWMgaN6S2tDVdokn-1'}
                                    text={'Посмотреть проект'}/>
                            </div>
                        </div>
                    </Slide>

                    <Slide bottom>
                        <div className={a.WrapperForCases2023}>
                            <img src="/open.webp" alt="openBank"/>
                            <div>
                                <div className={a.InfoCard}>
                                    <img src="/OpenBank/logo.svg" alt="k"/>
                                    <Title
                                        title='Интерфейс эффективности сотрудников банка'
                                    />
                                    <div className={a.BadgeWrapper}>
                                        <Badge text={'Дизайн'}/>
                                        <Badge text={'Прототипы'}/>
                                    </div>
                                </div>
                                <LinkButton
                                    url={'https://www.figma.com/file/gsv3nrM0rFsqxKM3OI0UbD/%D0%A1%D0%BE%D0%BB%D0%BE%D0%B2%D1%8C%D0%B5%D0%B2-%D0%90%D0%BB%D0%B5%D0%BA%D1%81%D0%B0%D0%BD%D0%B4%D1%80----UX-%D0%94%D0%B8%D0%B7%D0%B0%D0%B9%D0%BD%D0%B5%D1%80---%D0%A2%D0%B5%D1%81%D1%82%D0%BE%D0%B2%D0%BE%D0%B5-%D0%B7%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D0%B5---25.05.2022---%D0%91%D0%B0%D0%BD%D0%BA-%D0%9E%D1%82%D0%BA%D1%80%D1%8B%D1%82%D0%B8%D0%B5?type=design&node-id=0%3A1&mode=design&t=ds0vtL0PqDEWKniR-1'}
                                    text={'Посмотреть проект'}/>
                            </div>
                        </div>
                    </Slide>
                </section>

                <section id='case' className={a.WrapperCase}>
                    <div className={a.CaseTitleWrapper}>
                        <Title TitleBig={'Сайты'}/>
                        <Line/>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default Case;

