import React, {useEffect, useState} from 'react';
import d from './DesignStudio.module.scss'
import {Slide, Fade} from "react-reveal";
import Footer from "../../Components/Footer/Footer";
import AllWorks from "../Cases/AllWorks/AllWorks";
import {Link} from "react-router-dom";

export const DesignStudio = () => {
    return (
        <div id="outer-container" className={d.Wrapper}>
            <main id="page-wrap">
                <header className={d.header} id='start'>
                    <nav className={d.NavBar}>
                        <img className={d.avatar} src="/Ava.png" alt="avatar"/>
                        <div>Александр Соловьев <Link className={d.SecondText} to='/resume'>CV</Link></div>
                    </nav>
                </header>

                <div className={d.MainWrapper}>
                    <div className={d.Left}>
                        <div className={d.CardWrapper}>

                            <Slide top duration={800}>
                                <div className={d.CardOne}>
                                    <div className={d.Title}>
                                        Исследую. <br/>
                                        Проектирую. <br/>
                                        Разрабатываю.
                                    </div>

                                    <p>
                                        Полноценное ведение проектов с нуля.
                                        Лендинги, корпоративные сайты и порталы.
                                        Разработка 3D иллюстраций
                                    </p>
                                </div>
                            </Slide>

                            <div className={d.WrapperCardTwo}>
                                <Slide top duration={1200}>
                                    <div className={d.CardTwo}>
                                        <h3>Работал и делал проекты для:</h3>
                                        <ul>
                                            <li>
                                                <a target='_blank' href="https://mar-lab.ru">Market Lab ↗</a>
                                                <a target='_blank' href="https://tinkoff.ru">Тинькофф ↗</a>
                                                <a target='_blank' href="https://up2u-app.tilda.ws">UP2U ↗</a>
                                                <a target='_blank' href="https://uremont.com/">UREMONT Страхование ↗</a>
                                            </li>
                                        </ul>
                                        <p>и еще для 20+ разных компаний</p>
                                    </div>
                                </Slide>

                                <Fade duration={20000}>
                                    <a href="https://t.me/innv1" target="_blank" className={d.CTA}>
                                        Обсудить проект
                                    </a>
                                </Fade>
                            </div>
                        </div>
                        <div className={d.CardThree}>
                            <span>Скоро тут будут оформленные кейсы и примеры работ</span>
                        </div>
                    </div>

                    <div className={d.Right}>

                    </div>
                </div>

                {/*<AllWorks/>*/}

                {/*Монетка*/}
                {/*<section>*/}
                {/*    <Fade bottom>*/}
                {/*        <div className={d.CoinFlipWrapper}>*/}
                {/*            <img src="/DesignStudio/COIN.gif" alt="artsolovev"/>*/}
                {/*        </div>*/}
                {/*    </Fade>*/}
                {/*</section>*/}

                <footer>
                    <Footer/>
                </footer>
            </main>
        </div>
    )
}

export default DesignStudio;
